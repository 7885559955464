@use '../../variables.scss' as v;

div#Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: calc(100% - 40px);
    height: v.$footerHeight;
    background-color: v.$backgroundColor;
    text-align: center;
    padding: 20px;

    a {
        text-decoration: underline;
    }
}