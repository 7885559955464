@use '../../variables.scss' as v;

div#open-burger-menu {
    position: fixed;
    top: 10px;
    right: 10px;
    transform: translateX(calc(100% + 20px));
    width: 50px;
    height: 50px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    transition: 0.3s cubic-bezier(0, 0, 0, 1);
    overflow: hidden;

    &.show {
        top: 0;
        right: 0;
        transform: translateX(0);
        width: 100%;
        height: 100%;
        z-index: 1000;
        border-radius: 0;

        >div.lines-container {
            top: 20px;
            right: 20px;
        }

        >ul {
            display: flex;
        }
    }

    >ul {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;
        transition: inherit;
        animation: ulBurgerMenuAnimation .6s cubic-bezier(0, 0, 0, 1) forwards;
    }

    >div.lines-container {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 6px;
        align-items: flex-end;
        transition: inherit;
        z-index: 100;

        >div.burger-menu-line {
            height: 2px;
            width: 30px;
            background-color: #fff;
            transition: 0.4s;
            border-radius: 1px;

            &.top {
                transform: rotate(-45deg) translate(-6px, 6px);
            }

            &.middle {
                opacity: 0;
                width: 0;
            }

            &.bottom {
                transform: rotate(45deg) translate(-5px, -6px);
            }

            &.show {
                transform: rotate(0deg) translate(0px, 0px);
                opacity: 1;
                width: 100%;
            }
        }
    }
}

nav#NavBar {
    width: 100%;
    height: v.$navBarHeight;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;


    >ul {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        list-style: none;
    }

    &.hide {
        transform: translateY(-100%);
    }
}

@keyframes ulBurgerMenuAnimation {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
        gap: 0px;
    }

    100% {
        opacity: 1;
        gap: 20px;
    }
}

@media all and (max-width: v.$widthNavBarLimit) {
    nav#NavBar {
        transform: translateY(-100%);
    }

    div#open-burger-menu {
        transform: translateX(0);
    }
}