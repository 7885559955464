//import fonts from './fonts.scss';
@use './variables.scss' as v;

@font-face {
	font-family: "Overpass Mono";
	src: url('../public/fonts/Overpass_Mono/OverpassMono-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: "Unbounded";
	src: url('../public/fonts/Unbounded/Unbounded-VariableFont_wght.ttf') format('truetype');
}

* {
	margin: 0;
	padding: 0;
	font-family: "Unbounded", monospace;
	font-size: v.$fontSize;
	text-decoration: none;
	color: v.$textColor;
	transition: .3s cubic-bezier(0, -.07, 0, .99);
	scrollbar-width: none;
	-webkit-scrollbar-width: none;
	-moz-scrollbar-width: none;
	-ms-scrollbar-width: none;
	-webkit-scrollbar: none;
	-moz-scrollbar: none;
	-ms-scrollbar: none;

	// Hide scrollbar
	&::-webkit-scrollbar {
		display: none;
	}

	&::-webkit-scrollbar-track {
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		display: none;
	}

	&::-ms-overflow-style {
		display: none;
	}

	&::-ms-scrollbar-track {
		display: none;
	}

	&::-ms-scrollbar-thumb {
		display: none;
	}

	&::-ms-scrollbar {
		display: none;
	}
}

body {
	min-height: 100vh;
	background-color: v.$backgroundColor;
}

.contrasted {
	background-color: v.$contrastColor;
	color: v.$contrastTextColor;

	* {
		color: v.$contrastTextColor;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
button,
input,
select,
textarea {
	>span {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 1000;
}

*.title-font-size {
	font-size: v.$titleFontSize;
}

*.big-title-font-size {
	font-size: v.$bigTitlefontSize;
}

*.very-big-title-font-size {
	font-size: v.$veryBigTitlefontSize;
}

*.code-font {
	font-family: "Overpass Mono", monospace;
}

svg.icon {
	font-size: v.$iconSize;

	&.big-icon {
		font-size: v.$bigIconSize;
	}

	&.small-icon {
		font-size: v.$smallIconSize;
	}

	&.medium-icon {
		font-size: v.$mediumIconSize;
	}
}

a,
button,
select,
.clickable {
	&:active {
		outline: none;
		transform: scale(0.95);
		user-select: none;
	}
}

button:disabled {
	opacity: .5;
	cursor: not-allowed;
}

div#App.entering {
	animation: appEntering .3s ease-in-out;
}

div#App.exiting {
	animation: appExiting .3s ease-in-out;
	opacity: 0;
}

button {
	padding: 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

@keyframes appEntering {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes appExiting {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@media all and (max-width: 720px) {
	* {
		font-size: v.$fontSizeMobile;

		&.title-font-size {
			font-size: v.$titleFontSizeMobile;
		}

		&.big-title-font-size {
			font-size: v.$bigTitlefontSizeMobile;
		}

		&.very-big-title-font-size {
			font-size: v.$veryBigTitlefontSizeMobile;
		}
	}
}