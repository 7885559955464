@use '../../variables.scss' as v;

span.text-skeleton {
    font-size: inherit;
    height: 1.2em;
    width: 100%;
    display: block;
    background: rgb(186, 186, 186);
    background: linear-gradient(90deg, v.$loading_color_1 0%, v.$loading_color_2 50%, v.$loading_color_1 100%);
    background: linear-gradient(90deg, v.$loading_color_1, v.$loading_color_2, v.$loading_color_1);
    background-repeat: no-repeat;
    animation: text-skeleton-animation 1s ease-in-out infinite;
    background-size: 1200px 100%;
    border-radius: 10px;
}

span.text-component {
    hi-lt {
        font-weight: 1000;
        letter-spacing: 1px;
        position: relative;
        background: none;
        background-image: linear-gradient(120deg, v.$underlineColor 0%, v.$underlineColor 100%);
        background-repeat: no-repeat;
        background-size: 100% 0.3em;
        background-position: 0 80%;
    }
}

@keyframes text-skeleton-animation {
    0% {
        background-position: -1200px 0;
    }

    100% {
        background-position: calc(1200px + 100%) 0;
    }
}