div.landing-div {
    height: calc(100vh);
    position: relative;
    --space-between: 40px;
    overflow: hidden;

    div.titles-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        h1.title,
        h2.subtitle {
            width: 100%;
            text-align: center;
            text-shadow: 0 0 20px #ffffff60;
        }
    }
}