div.see-more-container {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    cursor: pointer;

    p {
        letter-spacing: 2px;
        width: 100%;
        text-shadow: 0 0 20px #ffffff60;
    }

    svg.icon {
        animation: iconAnimation 1s infinite;
        filter: drop-shadow(0 0 20px #ffffff60);
    }
}