$navBarHeight: 90px;
$fontSize: 20px;
$fontSizeMobile: 17px;
$titleFontSize: 30px;
$titleFontSizeMobile: 20px;
$bigTitlefontSize: 50px;
$bigTitlefontSizeMobile: 35px;
$veryBigTitlefontSize: 70px;
$veryBigTitlefontSizeMobile: 9vw;
$textColor: #fff;
$loading_color_1: transparent;
$loading_color_2: rgba(186, 186, 186, 1);
$iconSize: 40px;
$bigIconSize: 60px;
$smallIconSize: 30px;
$mediumIconSize: 50px;
$contrastColor: #c9c9c9;
$contrastTextColor: #000;
$underlineColor: #e9dc668a;
$widthNavBarLimit: 700px;
$footerHeight: 100px;
$backgroundColor: #131313;
$greenColor: #0d920d;
$redColor: #a51f1f;