svg.ellipse-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    >g {
        >ellipse {

            &.ellipse-bg-follow-mouse {
                transition: opacity 0.5s ease-in-out;
                animation: none !important;
                opacity: 0;
            }

            &.ellipse-bg-respiration {
                animation: respiration 10s infinite;
                animation-delay: 1s;
            }
        }
    }
}

@keyframes respiration {
    0% {
        rx: 200px;
        ry: 200px;
    }

    50% {
        rx: 250px;
        ry: 250px;
    }

    100% {
        rx: 200px;
        ry: 200px;
    }
}