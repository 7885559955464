.Argument {
    h3.argument-title {
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    div.argument-content {
        margin: 20px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}