#About {
    --space-between: 40px;

    >div.content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;

        >div {
            width: calc(100% - 40px);
            padding-top: 60px;
            padding-bottom: 60px;
            padding-left: 20px;
            padding-right: 20px;
            max-width: 1300px;
            display: flex;
            flex-direction: column;
            gap: 60px;

            h2.content-title {
                padding-top: 30px;
                padding-bottom: 30px;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    div#About {
        div.landing-div {
            height: calc(100vh - 250px);
        }
    }
}