@use '../../variables.scss' as v;

div#Home {
    div.contrasted-container {
        width: 100%;

        div.what-we-do,
        div.what-you-must-know {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(100% - 40px);
            max-width: 1300px;
            margin: auto;
            padding: 20px;

            h2.what-we-do-title,
            h2.what-you-must-know-title {
                padding-top: 50px;
                padding-bottom: 30px;
                text-align: center;
            }

            >ul {
                padding-top: 40px;
                padding-bottom: 60px;
                display: flex;
                flex-direction: column;
                gap: 60px;

                >li {
                    display: inline;
                }
            }

            p {
                padding: 10px;
            }
        }
    }

    div.why-would-you-buy {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        max-width: 1300px;
        margin: auto;

        h2.why-would-you-buy-title {
            text-align: center;
        }
    }
}

@keyframes iconAnimation {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 1000px) {
    div#Home {
        div.landing-div {
            height: calc(100vh - 250px);
        }
    }
}