@use "../../variables.scss" as v;

div#Contact {
    div.contact-section {
        >h2 {
            padding-top: 50px;
            padding-bottom: 30px;
            text-align: center;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            padding: 20px;
            width: calc(100% - 40px);
            max-width: 600px;
            margin: 0 auto;

            >div.form-group {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;

                >label {
                    font-size: 1.2rem;
                    font-weight: 600;
                }

                >input,
                >textarea {
                    padding: 10px;
                    border: none;
                    border-radius: 5px;
                    font-size: 1.2rem;
                    font-weight: 400;
                    outline: none;
                    transition: .2s ease-in-out;

                    &:focus {
                        box-shadow: 0 0 10px #00000085;
                    }
                }
            }

            p.error-text {
                color: v.$redColor;
                font-weight: 600;
                text-align: center;
            }

            p.success-text {
                color: v.$greenColor;
                font-weight: 600;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    div#Contact {
        div.landing-div {
            height: calc(100vh - 250px);
        }
    }
}